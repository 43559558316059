import { firestoreAction } from 'vuexfire'
import moment from 'moment'
import { firestore, Timestamp } from '@/plugins/firestore'

const COLLECTION_NAME_MESSAGE =
  process.env.ctiEnv === 'production'
    ? 'messages_hayama'
    : 'messages_hayama_dev'
const messageRef = firestore.collection(COLLECTION_NAME_MESSAGE)

export const state = () => ({
  messages: [],
  readMessages: [],
  searchedMessages: []
})

export const getters = {
  messages: (_state) => {
    return messageRef
  }
}

export const actions = {
  init: firestoreAction(({ bindFirestoreRef }) => {
    const ref = messageRef
      .where('created_at', '>=', Timestamp.fromDate(new Date()))
      .orderBy('created_at', 'desc')
    bindFirestoreRef('messages', ref)
  }),

  getRecentMessages: firestoreAction(({ bindFirestoreRef }) => {
    const ref = messageRef.orderBy('created_at', 'desc').where(
      'created_at',
      '>=',
      Timestamp.fromDate(
        moment()
          .subtract(365, 'days')
          .toDate()
      )
    )
    bindFirestoreRef('searchedMessages', ref)
  }),

  getMessageById: (_context, { id }) => {
    return messageRef
      .doc(id)
      .get()
      .then((doc) => (doc.exists ? doc.data() : false))
  }
}

export const mutations = {
  markAsReadMessage(state, { message }) {
    state.readMessages.push(message)
  }
}
